<template>
  <span class="regular-12 badge w-100 m-2" :class="color">
    <span v-if="extraRow" class="px-3">
      {{ row?.values[value][row?.label][extraRow] }}
    </span>
    <span v-else-if="row.type === 'object'"> {{ value[row?.label] }} </span>
    <EditOnClick
      v-else
      v-model="value"
      :type="row.type"
      :values="row?.values"
      :select-key="row?.selectKey"
      :label="row?.label"
      :name="row?.name"
      :editable="row?.editable"
    />
  </span>
</template>

<script>
import EditOnClick from "../../inputs/EditOnClick";
export default {
  name: "Tag",
  components: { EditOnClick },
  props: {
    id: Number,
    row: [Object, Array],
    itemType: String,
    modelValue: [Object, Array, Number, String],
    extraRow: [Object, Array, Number, String],
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  computed: {
    color() {
      if (this.row.type === "object") {
        if (this.value?.color !== undefined) {
          return this.value?.color;
        }
      }
      if (
        this.row.type === "enum" &&
        this.row.values?.length > 0 &&
        Array.isArray(this.row.values)
      ) {
        const element = this.row.values.find(
          (d) => d[this.row.selectKey] === this.value
        );
        if (element?.color !== undefined) {
          return element?.color;
        }
      } else if (typeof this.row.values === "object") {
        return this.row.values[this.value]?.color;
      }
      return null;
    },
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
